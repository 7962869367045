import React, {useEffect} from "react"
import {graphql} from "gatsby";
import {loadScript} from "../util/helper";
import Layout from "../components/layout/Layout";
import {useTranslation} from "gatsby-plugin-react-i18next";
// markup
const NotFoundPage = () => {

    const { t} = useTranslation()
    useEffect(() => {
        loadScript("/assets/js/plugins.js");
        setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 500)
    })

    return (
        <>
            <Layout title={'OHADA | A propos de nous'}>
                <section className="wrapper bg-light">
                    <div className="container pt-12 pt-md-14 pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-9 col-xl-8 mx-auto">
                                <figure className="mb-10"><img className="img-fluid"
                                                               src="/assets/img/illustrations/404.png"
                                                               srcSet="/assets/img/illustrations/404@2x.png 2x" alt=""/>
                                </figure>
                            </div>
                            <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                                <h1 className="mb-3">{t('pageNotFound')}</h1>
                                <p className="lead mb-7 px-md-12 px-lg-5 px-xl-7">{t('pageNotFoundMessage')}</p>
                                <a href="/" className="btn btn-primary rounded-pill">{t('goHome')}</a>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>

    )
}

export default NotFoundPage


export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

